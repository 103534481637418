import InnerPageContainer from '@/components/layout/innerPageContainer'
import { NavLayout } from '@/components/layout/navLayout'
import { isCurrentEnvironmentOneOf } from '@/util/isEnv'
import buildLogger from '@/util/logger'
import { Grid } from '@mui/material'
import Head from 'next/head'
import Image from 'next/image'

import BillyLinkCard, { LinkCardProps } from '@/components/card/billyLinkCard'
import { ScienceOutlined } from '@mui/icons-material'
const logger = buildLogger('LaboratoryPage')

export const LABORATORY_ROUTES: LinkCardProps[] = [
  {
    title: 'Charts',
    subheader: 'Experiment on charts',
    url: 'laboratory/ui-charts',
  },
  {
    title: 'DGP ElasticSearch Table',
    subheader: 'Experiment on using DataGridPro table component for ElasticSearch.',
    url: 'laboratory/dgp-es-table',
  },
  {
    title: 'UI Only Stepwise Order',
    subheader:
      'Experiment on the entire order create & edit process. A new take which breaks thing down into steps, which is done purely with UI refactor.',
    url: 'laboratory/stepwise-orders-ui-only',
  },
  {
    title: 'Create Order With Start Date',
    subheader:
      'Experiment on creating an order with a start date before entering the main edit page. Provide a deliberate way to set the start date.',
    url: 'laboratory/create-order-with-start-date',
  },
  {
    title: 'Stepwise Order',
    subheader:
      'Experiment on the entire order create & edit process. A new take which breaks thing down into steps, and allows for more flexibility.',
    url: 'laboratory/stepwise-orders',
  },
  {
    title: 'DataDog Alert Test',
    subheader: 'Experiment different page behavior when there is an error.',
    url: 'laboratory/datadog-alerts',
    hide: !isCurrentEnvironmentOneOf('local', 'dev2'),
  },
].filter((route) => !route.hide)

export function LaboratoryPage(): JSX.Element {
  const breadcrumbs = [
    {
      label: '',
      link: '/',
      icon: <Image src="/icons/home-sm.svg" alt="home" width={20} height={20} />,
    },
    {
      label: 'Laboratory',
      link: '/laboratory',
    },
  ]

  return (
    <>
      <Head>
        <title>Laboratory | Subskribe</title>
      </Head>
      <NavLayout breadcrumbs={breadcrumbs}>
        <InnerPageContainer>
          <Grid container rowGap={3} columnSpacing={3}>
            {LABORATORY_ROUTES.map((link) => (
              <Grid item xs={12} md={6} lg={4} key={link.url}>
                <BillyLinkCard avatar={<ScienceOutlined width={28} height={28} color="primary" />} {...link} />
              </Grid>
            ))}
          </Grid>
        </InnerPageContainer>
      </NavLayout>
    </>
  )
}
