import { OrderAmendOrCancelDate } from '@/pageComponents/orders/AmendmentOrderPage/OrderAmendOrCancelDate'
import { CardContent, CardHeader, Grid, Typography } from '@mui/material'
import BillyCard from '../../../components/card/billyCard'
import JotaiUrqlErrors from '../../../components/error/jotaiUrqlErrors'
import JotaiMuiCheckbox from '../../../components/input/JotaiMuiCheckbox'
import { JotaiUrqlBillySnackBar } from '../../../components/snackBar/useJotaiUrqlBillySnackBar'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import JotaiReadValue from '../../../components/state/jotaiReadValue'
import { UpsertAmendmentDocument } from '../../../generated/graphql'
import { AmendCancelCustomerDetailsSection } from '../EditOrderPage/cards/BillyOrderDetailsCard/DetailsGridLayout'
import { OrderDetailsSection } from '../EditOrderPage/cards/BillyOrderDetailsCard/OrderDetailsSection'
import { AmendSubscriptionPageState } from './AmendmentOrderPage'
import { useAmendmentOrderPageStyles } from './useAmendmentOrderPageStyles'
import { OrderCurrencySelect } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/OrderCurrencySelect'
import { useCustomizationProps, WithCustomizationLocator } from '@/components/state/context/customizationContext'

export function AmendmentOrderDetailsCard({ parentLocator }: WithCustomizationLocator): JSX.Element {
  const jotaiForm = useJotaiFormContext<AmendSubscriptionPageState>()
  const { classes } = useAmendmentOrderPageStyles()

  const customizationProps = useCustomizationProps({ displayName: 'autoRenew', parentLocator })
  const hidden = customizationProps?.hidden
  const defaultChecked = customizationProps?.defaultChecked

  return (
    <BillyCard>
      <JotaiReadValue
        atomSelector={(form: AmendSubscriptionPageState) => form.orderDetail.account?.name}
        form={jotaiForm}
        render={(accountName) => <CardHeader title={accountName} subheader="Order Details" />}
      />
      <CardContent>
        <JotaiUrqlErrors jotaiForm={jotaiForm} />
        <JotaiUrqlBillySnackBar document={UpsertAmendmentDocument} jotaiForm={jotaiForm} />
        <OrderDetailsSection />
        <AmendCancelCustomerDetailsSection />

        <Typography component="h6" className={classes.rowLabel}>
          Subscription Details
        </Typography>
        <Grid container direction="row" spacing={2}>
          <OrderAmendOrCancelDate />
          <OrderCurrencySelect disabledExplanation="Amendments do not support changing currencies" />
          <section hidden={hidden}>
            <Grid item lg={4} xl={2} className={classes.fieldGridItem} direction="row" spacing={2} hidden={true}>
              <JotaiMuiCheckbox
                atomSelector={(form) => form.orderDetail.autoRenew}
                form={jotaiForm}
                checkboxProps={{
                  defaultChecked,
                }}
                atomUpdater={(value, draft) => (draft.orderDetail.autoRenew = value)}
                fieldLabel="Auto-Renew"
              />
            </Grid>
          </section>
        </Grid>
      </CardContent>
    </BillyCard>
  )
}
