import { ThemeOptions } from '@mui/material/styles/createTheme'

// https://mui.com/material-ui/customization/default-theme/ has the default theme and if not expectedly set here
const theme: Partial<ThemeOptions> = {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: 'solid 1px #D6DDE4',
          borderRadius: 6,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          // The default zIndex for dialogs in MUI is 1300.  We need
          // autocomplete to rest on top of dialogs in all situations.  This has
          // to be a magic number here because there's no way to read the current
          // zIndex configuration of the theme here since this is static code.
          zIndex: 1400,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingRight: 24,
          paddingBottom: 24,
          paddingLeft: 24,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: { padding: 24 },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: { ':first-of-type': { paddingLeft: 24 }, ':last-of-type': { paddingRight: 24 } },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          margin: 0,
        },
        root: {
          padding: '16px 24px',
        },
        title: {
          fontSize: 18,
          fontWeight: 600,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: { minWidth: 48 },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: { cursor: 'pointer', textDecoration: 'none' },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ['& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button']: {
            display: 'none',
          },
          ['& input[type=number]']: {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          paddingLeft: 2,
          paddingRight: 2,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: 'fullWidth',
      },
      styleOverrides: {
        root: {
          paddingTop: '1rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          backgroundColor: '#FFFFFF',
          borderBottom: 'solid 1px #D6DDE4',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'right',
        arrow: true,
      },
    },
    MuiDataGrid: {
      defaultProps: {
        disableRowSelectionOnClick: true,
        disableColumnResize: true,
        disableColumnMenu: true,
        hideFooter: true,
        disableColumnReorder: true,
        disableColumnSelector: true,
        disableMultipleRowSelection: true,
      },
      styleOverrides: {
        root: {
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { paddingTop: '0.5rem', paddingBottom: '0.5rem' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { paddingTop: '1rem', paddingBottom: '1rem' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { paddingTop: '1rem', paddingBottom: '1rem' },
        },
        cell: {
          '&:focus-within': {
            outline: 'none',
          },
          paddingRight: '0.5rem',
          paddingLeft: '0.5rem',
          '&:first-of-type': {
            paddingLeft: '1rem',
          },
          '&+div:last-of-type': {
            paddingRight: '1rem',
          },
          '&[data-field="quantity"]': {
            overflow: 'visible!important',
          },
          '&[data-field="listPrice"]': {
            overflow: 'visible!important',
          },
        },
        columnHeader: {
          maxHeight: 52,
          '&:focus-within': {
            outline: 'none',
          },
          paddingRight: '0.5rem',
          paddingLeft: '0.5rem',
          '&:first-of-type': {
            paddingLeft: '1rem',
          },
          '&+div:last-of-type': {
            paddingRight: '1rem',
          },
          '&:has(.MuiCheckbox-root)': {
            paddingLeft: '0.5rem',
          },
        },
        columnHeaderDraggableContainer: {
          width: 'unset',
          flexBasis: '100%',
          overflow: 'hidden',
        },
        columnHeaders: {
          background: '#F9FAFB',
          '& .MuiInput-root.MuiInput-underline.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
        columnHeaderTitle: {
          lineHeight: '1.5rem',
          fontSize: '0.75rem',
          fontWeight: 700,
          color: '#595959!important',
        },
        cellContent: {
          '&:focus': {
            outline: 'none',
          },
          fontSize: '1rem',
          fontWeight: 400,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Manrope, sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 550,
    fontWeightMedium: 600,
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
}

export default theme
