import { billyRestClient } from '@/components/data/billyRestClient'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { Feature } from '@/generated/graphql'
import buildLogger from '@/util/logger'
import { GridProps } from '@mui/material'
import { PropertyPath, set } from 'lodash'
const logger = buildLogger('CustomizationContext')
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'

export type Customization = {
  id: string
  label?: string
  hidden?: boolean
  required?: boolean
  style?: React.CSSProperties
  configType?: 'dgpTable' | 'form' | 'checkbox'
  columnOrdering?: string[]
  columnDefaultInvisible?: string[]
  // anything can be updated in the customizations
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValueSetters?: { path: PropertyPath; value: any }[]
} & GridProps
set

export type WithCustomizationComponentProps = Customization & {
  columnOrderNormalized: Record<string, number>
}

type CustomizationProps = Customization[]

const DEFAULT_CUSTOMIZATION_CONTEXT: CustomizationProps = []

const CustomizationContext = createContext<CustomizationProps>(DEFAULT_CUSTOMIZATION_CONTEXT)

// TODO: anything can be updated in the customizations for now until BE updates it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UpdateAsyncFn = (body: any) => Promise<void> | void
const CustomizationActionContext = createContext<{
  updateAsync: UpdateAsyncFn
}>({
  updateAsync: () => {
    console.log({ message: 'updateAsync not implemented' })
  },
})

export const useCustomizationsContext = () => {
  return useContext(CustomizationContext)
}
export const useCustomizationsActions = () => {
  return useContext(CustomizationActionContext)
}

function mapCustomizationToComponentProps(props?: Customization): WithCustomizationComponentProps | undefined {
  try {
    return props
      ? {
          ...props,
          'aria-label': props.id,
          columnOrderNormalized: props.columnOrdering
            ? props.columnOrdering.reduce((acc, column, index) => {
                acc[column] = index
                return acc
              }, {})
            : {},
        }
      : undefined
  } catch (err) {
    console.error({ message: 'Error mapping customization', props, err })
  }
}

export const CustomizationProvider = ({ children }) => {
  const [customizations, setCustomizations] = useState(DEFAULT_CUSTOMIZATION_CONTEXT)
  const errorHandler = useErrorHandler()

  const isTenantUiCustomizationEnabled = useDynamicFeatureFlag(Feature.TenantUiCustomization)

  async function fetchAsync() {
    if (isTenantUiCustomizationEnabled) {
      const { data } = await billyRestClient.settings.getTenantUiCustomizationConfig()
      setCustomizations(data.customizations)
    }
  }
  const updateAsync: UpdateAsyncFn = async (body) => {
    await billyRestClient.settings.updateTenantUiCustomizationConfig(body)
    await fetchAsync()
  }

  useEffect(() => {
    fetchAsync().catch(errorHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isTenantUiCustomizationEnabled ? (
    <CustomizationActionContext.Provider value={{ updateAsync }}>
      <CustomizationContext.Provider value={customizations}>{children}</CustomizationContext.Provider>
    </CustomizationActionContext.Provider>
  ) : (
    <>{children}</>
  )
}

export type CustomizationLocator = '' | 'order::new::' | 'order::amendment::'
export type WithCustomizationLocator = {
  parentLocator?: CustomizationLocator
}
export type WithCustomizationContextInput = {
  customizations?: CustomizationProps
}

export function useCustomizationProps({
  displayName,
  parentLocator,
}: {
  displayName: string
} & WithCustomizationLocator) {
  const customizations = useCustomizationsContext()
  const customizationProps = useMemo(
    () =>
      mapCustomizationToComponentProps(
        customizations?.find((customization) => customization.id === parentLocator + displayName)
      ),
    [customizations, displayName, parentLocator]
  )

  return customizationProps
}

export function withYupSchemaWithCustomization({
  schema,
  customizations,
  parentLocator,
}: {
  // any schema for the HOF
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: yup.ObjectSchema<any>
} & WithCustomizationContextInput &
  WithCustomizationLocator) {
  const possibleFields = ['sfdcOpportunityId']
  const newValidationRules = possibleFields.reduce((acc, field) => {
    const customization = customizations?.find((customization) => customization.id === parentLocator + field)
    if (customization?.required) {
      return {
        ...acc,
        [field]: schema.fields[field] ? schema.fields[field].required() : yup.string().trim().required(),
      }
    } else {
      return acc
    }
  }, {} as Record<string, yup.AnySchema>)

  return schema.shape(newValidationRules)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withCustomizationDefaultValues<V extends object = any>(
  defaultValueSetters: Customization['defaultValueSetters'],
  defaultValue: V
): V {
  try {
    const ret = (defaultValueSetters ?? []).reduce((result, setter) => {
      const { path, value } = setter
      return set(result, path, value)
    }, defaultValue)
    logger.info({
      message: 'rg Set default values based on customizations',
      ret,
    })
    return ret
  } catch (err) {
    logger.error({
      message: "Couldn't set default values based on customizations",
      err,
      defaultValueSetters,
      defaultValue,
    })
    return defaultValue
  }
}
